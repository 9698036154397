import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Headline from "../components/headline"
import theme from "../theme"

const HeroContainer = styled.div`
  position: relative;
`

const HeroTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 50%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    margin: 0;
    padding: 0;
  }
`

const HeroSubtitle = styled.div`
  line-height: 1.5;
  font-size: calc(18 / 16 * 1rem);
  font-weight: 300;
  text-align: center;
  width: 100%;
  margin-top: 0.25em;

  @media (min-width: ${theme.breakpoint.large}) {
    font-size: calc(24 / 20 * 1.25rem);
  }
`

const HeroCopyright = styled.div`
  line-height: 1.5;
  font-weight: 300;
  text-align: center;
  width: 100%;
  margin-top: 0.25em;
`

const Hero = (props) => (
  <StaticQuery
    query={graphql`
      {
        images: allFile(
          filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }
        ) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => {
        return n.node.relativePath.includes(props.filename)
      })

      if (!image) {
        return null
      }

      return (
        <HeroContainer id={props.id}>
          {props.text ? (
            <HeroTitle>
              <Headline primary text={props.text} />
            </HeroTitle>
          ) : null}
          <GatsbyImage
            image={image.node.childImageSharp.gatsbyImageData}
            alt={props.title || ""}
            width={image.node.childImageSharp.presentationWidth}
            height={image.node.childImageSharp.presentationHeight}
          />
          {props.subtitle ? (
            <HeroSubtitle>{props.subtitle}</HeroSubtitle>
          ) : null}
          {props.copyright ? (
            <HeroCopyright>{props.copyright}</HeroCopyright>
          ) : null}
        </HeroContainer>
      )
    }}
  />
)

export default Hero
