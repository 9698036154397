import React from "react"

import Layout from "../layouts/layout"
import Hero from "../components/hero"
import Seo from "../components/seo"

import Text from "../components/text"
import Headline from "../components/headline"
import Block from "../components/block"
import Logo from "../components/logo"

const IndexPage = () => (
  <Layout>
    <Seo title="HAGEN HANSEN Designbüro" />

    <Block>
      <Logo />
      <Headline text="Hagen Hansen" primary />
    </Block>

    <Hero text="Designbüro" filename="designbuero.png" />

    <Block>
      <Text>
        <h3>
          Konzeptionelle Designentwicklung für Marketingstrategien sowie deren
          Realisierung in kulturellen, touristischen, sozialen und
          unternehmerischen Strukturen.
        </h3>
      </Text>
    </Block>

    <Hero text="Facts" filename="facts.png" />

    <Block id="facts">
      <Text>
        <h3>· Ganzheitliche Umsetzung im Print-, Web- und Objektbereich</h3>
        <h3>· lösungsorientierte Grundhaltung </h3>
        <h3>· hohe Sinnesaufmerksamkeit</h3>
        <h3>· Entscheidungs- und Fachkompetenz</h3>
        <h3>· entspannte Präsentations- und Entwicklungsumgebung</h3>
        <h3>· umfangreiche zeitgemäße Arbeitstechnologien</h3>
        <h3>· Motivation</h3>
        <h3>· Produktionsstandort Deutschland</h3>
        <h3>· Internetrealisierungen Serverstandort Deutschland</h3>
        <h3>· 100 Prozent erneuerbarer Energiebezug seit 2010</h3>
        <h3>· Firmengründung 1995</h3>
      </Text>
    </Block>

    <Hero text="Design" filename="design.png" />

    <Block id="design">
      <Text>
        <h3>Brand | Corporate | Objekt | Web | Print</h3>
        <p>
          Als Unternehmen aus verschiedenen Branchen, Kommune oder Gesellschaft
          benötigen Sie individuelle Lösungen für Ihre Projekte? 
        </p>
        <p>
          <a href="mailto:info@hagenhansen.de">» Kontaktieren Sie uns</a>
        </p>
      </Text>
    </Block>

    <Hero text="ECM Musicstore" filename="ecmmusicstore-21.jpg" />

    <Block id="ecmmusicstore">
      <Text>
        <h3>
          Präsentation und Verkauf von Musikproduktionen des Labels ECM Records
        </h3>
        <p>
          Im ECM Musicstore können interessierte Besucher musikalische
          Highlights entdecken und in audiophiler Qualität probehören.
        </p>

        <p>
          <a href="https://ecmmusicstore.de">» zur Website</a>
        </p>
      </Text>
    </Block>

    <Hero text="Ausstellungen" filename="ausstellungen.png" />

    <Block id="ausstellungen">
      <Text>
        <h3>
          Thematisch angelegte Ausstellungen im Raumlicht-Konzept des
          Designbüros
        </h3>
        <p>Aktuelle Ausstellung: ECM – Musik und Hintergrundinformationen</p>
      </Text>
    </Block>
    <Block id="kontakt">
      <Headline text="Kontakt" primary />
      <Text>
        <p>
          HAGEN HANSEN DESIGNBÜRO
          <br />
          Paetowweg 6<br />
          D-18347 Ostseebad Ahrenshoop
          <br />
          Deutschland
          <br />
          Telefon: <a href="tel:+493822066770">+49 38220 66770</a>
          <br />
          Fax: +49 38220 667722
          <br />
          E-Mail:{" "}
          <a href="mailto:info@hagenhansen.de">info [at] hagenhansen.de</a>
        </p>
      </Text>
    </Block>
  </Layout>
)

export default IndexPage
